@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-component-typographies();
@include mat.elevation-classes();
@include mat.app-background();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue.
/* Color definitions */
$mat-custom-primary: (
  50: white,
  100: #e1e1e1,
  200:  white,
  300:  white,
  400:  white,
  500: #396099,
  600:  white,
  700:  white,
  800:  white,
  900:  white,
  A100: #396099,
  A200: #396099,
  A400: #396099,
  A700: #396099,

  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$mat-custom-accent: (
  50: #D7001E,
  100: #D7001E,
  200:  #D7001E,
  300:  #D7001E,
  400:  #D7001E,
  500: #D7001E,
  600:  #D7001E,
  700:  #D7001E,
  800:  #D7001E,
  900:  #D7001E,
  A100: #D7001E,
  A200: #D7001E,
  A400: #D7001E,
  A700: #D7001E,

  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$candy-app-primary: mat.m2-define-palette($mat-custom-primary);
$candy-app-accent:  mat.m2-define-palette($mat-custom-accent);

// The warn palette is optional (defaults to red).
$candy-app-warn:    mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$candy-app-theme: mat.m2-define-light-theme((
        color: (
          primary: $candy-app-primary,
          accent: $candy-app-accent,
          warn: $candy-app-warn
        ),
        typography: mat.m2-define-typography-config(),
        density: 0
       ));


// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($candy-app-theme);
