textarea {
  resize: none;
}
span.item{
  width: 100px;
  height: 100px;
  display: inline-block
}
.form_actions{
 width: 100%;
 height: 50px;
}
.form_actions button{
  float: right;
}

/*primeng*/
.ui-g{
  height: 100%;
  width: 100%;
  position: relative;
}
.ui-g-1{
  height: 100%;
  position: relative;
}
.ui-g-4{
  height: 100%;
  position: relative;
}
.ui-g-8{
  height: 100%;
  position: relative;
}
.ui-g-9{
  height: 100%;
  position: relative;
}
.ui-g-11{
  height: 100%;
  position: relative;
}
.ui-g-12{
  height: 100%;
  position: relative;
}
.ui-fluid{
  margin-left: 1vw;
  width: 100%;
}

/*Material io*/
mat-form-field{
  width:100%;
  font-size: 14px;
}
mat-select{
  width: 100%;
  font-size: 14px;
}
mat-list-item{
  height: 5vw ;
  display: flex !important;
  align-items: center;
}
mat-header-cell{
  font-size: 14px !important;
}
mat-cell{
  font-size: 14px !important;
}
button{
  font-size: 16px !important;
}
.mat-mdc-select {
  margin-top: 20px;
  min-width: 50%;
}
.mat-simple-snackbar-action{
  color: white !important;
}
.mat-mdc-select-panel{
  max-height: 500px !important;
}
.mat-grid-tile .mat-figure {
  padding: 3px !important;
}
.mat-mdc-list .mat-mdc-list-item .mat-mdc-list-item-content, .mat-mdc-nav-list .mat-mdc-list-item .mat-mdc-list-item-content, .mat-mdc-selection-list .mat-mdc-list-item .mat-mdc-list-item-content{
  width: 100% !important;
  height: 80% !important;
}
.mat-toolbar h1{
  font-size: 18px !important;
}
.mat-mdc-dialog-title{
  text-align: center;
  color: #396099;
  font-size: 24px !important;
}
.mat-mdc-select-placeholder{
  font-size: 14px;
}
.mat-mdc-input-element{
  font-size: 14px;
}
.mat-mdc-select-value{
  font-size: 14px;
}
.hint_errors{
  color: red;
  white-space: nowrap;
}
.errors_span{
  color: red;
  font-size: 12px;
}
.mat-sidenav-content{
  height: 100%;
  overflow: hidden !important;
}
.mat-mdc-dialog-container{
  height: unset  !important;
  max-width: 95vw !important;
}
mat-icon img{
  height: 20px;
}

/*Google Icons fonts */

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(../assets/fonts/MaterialIcons-Regular.eot); /* For IE6-8 */
  src: local('Material Icons'),
       local('MaterialIcons-Regular'),
       url(../assets/fonts/MaterialIcons-Regular.woff2) format('woff2'),
       url(../assets/fonts/MaterialIcons-Regular.woff) format('woff'),
       url(../assets/fonts/MaterialIcons-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'),
       local('Roboto-Regular'),
       url(../assets/fonts/Roboto-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto Medium';
  font-style: normal;
  font-weight: 500;
  src: local('Roboto Medium'),
       local('Roboto-Medium'),
       url(../assets/fonts/Roboto-Medium.ttf) format('truetype');
}

.material-icons {
  font-family: 'Material Icons', serif;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

* {
  letter-spacing: normal !important;
}


/*Globals*/
.hint_errors{
  color: red;
  white-space: nowrap;
}
